<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <el-icon>
            <menu />
          </el-icon>
          Email Template
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-input
          v-model="query.name"
          placeholder="Template Name"
          class="handle-input mr10"
          @keyup.enter="handleSearch"
          :clearable="true"
        ></el-input>
        <el-button type="primary" @click="handleSearch">
          <el-icon>
            <search />
          </el-icon>
          Search
        </el-button>
        <el-button type="primary" @click="handleCreate" style="float: right">
          <el-icon>
            <plus />
          </el-icon>
          Create a Template
        </el-button>
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @sort-change="changeSort"
        :default-sort="{ prop: 'CreatedTime', order: 'ascending' }"
      >
        <el-table-column
          prop="name"
          label="Name"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="Name"
          width="200"
        ></el-table-column>
        <el-table-column prop="subject" label="Subject"></el-table-column>
        <el-table-column prop="enableHTML" label="Enable HTML" width="100">
          <template #default="scope">
            {{ scope.row.enableHTML === true ? "Yes" : "No" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="Status"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="updatedTime"
          sortable="true"
          :formatter="$tableCellFormatter.formatDate"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="UpdatedTime"
          label="Updated Time"
          width="120"
        ></el-table-column>
        <el-table-column label="Operation" width="180" align="center">
          <template #default="scope">
            <el-button type="text" @click="handleEdit(scope.$index, scope.row)">
              <el-icon>
                <edit />
              </el-icon>
              Edit
            </el-button>
            <el-button
              type="text"
              class="red"
              @click="handleDelete(scope.$index, scope.row)"
            >
              <el-icon>
                <delete />
              </el-icon>
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="itemTotal"
          :page-count="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>

      <el-dialog :title="dialogTitle" v-model="editVisible" width="60%">
        <el-form
          ref="mainForm"
          :model="form"
          :rules="rules"
          label-width="120px"
        >
          <el-form-item prop="name" label="Name">
            <el-input v-model="form.name" :readonly="editMode"></el-input>
          </el-form-item>
          <el-form-item prop="subject" label="Subject">
            <el-input v-model="form.subject"></el-input>
          </el-form-item>
          <el-form-item label="Content">
            <ckeditor
              :editor="editor"
              v-model="form.content"
              :config="editorConfig"
            ></ckeditor>
          </el-form-item>
          <el-form-item prop="enableHTML" label="Enable HTML">
            <el-checkbox v-model="form.enableHTML"></el-checkbox>
          </el-form-item>
          <el-form-item prop="status" label="Status">
            <el-select v-model="form.status" placeholder="Please select...">
              <el-option label="Normal" value="Normal"></el-option>
              <el-option label="Draft" value="Draft"></el-option>
              <el-option label="Deleted" value="Deleted"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="editVisible = false">Cancel</el-button>
            <el-button type="primary" @click="saveEdit">Save</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getData, putData, postData, deleteData } from "../../service/api";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "emailTemplateList",
  data() {
    return {
      controllerUrl: "/emailTemplate",
      query: {
        templateId: null,
        name: null,
        title: null,
        orderBy: "CreatedTime",
        orderDirection: "ASC",
        pageIndex: 1,
        pageSize: 20,
      },
      editor: ClassicEditor,
      tableData: [],
      dialogTitle: "",
      editMode: false,
      editVisible: false,
      itemTotal: 0,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      rules: {
        name: [
          {
            required: true,
            message: "Please input template Name",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "Please select template status",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getData(this.controllerUrl, this.query).then((res) => {
        console.log(res);

        let resData = res.result;
        this.tableData = resData.list;
        this.query.pageIndex = resData.pageIndex;
        this.query.pageSize = resData.pageSize;
        this.itemTotal = resData.count;
        this.pageTotal = Math.ceil(resData.count / resData.pageSize);
      });
    },
    handleSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    changeSort(sortData) {
      console.log(sortData);
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection =
          sortData.order === "ascending" ? "asc" : "desc";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
      this.loadData();
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    handleDelete(index, row) {
      // twice to confirm to delete
      this.$confirm("Are you sure to delete this item？", "Prompt", {
        type: "warning",
      })
        .then(() => {
          console.log(row);
          deleteData(this.controllerUrl, row.templateId).then((res) => {
            if (res.result && res.code === "200") {
              this.$message.success("Delete successfully");
              this.tableData.splice(index, 1);
              //this.loadData();
            } else {
              this.$message.error(
                "Delete failed, error message: " + res.message
              );
            }
          });
        })
        .catch(() => {});
    },
    handleEdit(index, row) {
      console.log(row);
      this.dialogTitle = "Edit";
      this.idx = index;
      this.form = row;
      this.editVisible = true;
      this.editMode = true;
    },
    handleCreate() {
      this.form = { enableHTML: true, status: "Normal" };
      this.dialogTitle = "New";
      this.editVisible = true;
      this.editMode = false;
    },
    saveEdit() {
      this.$refs.mainForm.validate((valid) => {
        if (valid) {
          if (this.editMode) {
            putData(this.controllerUrl, this.form.templateId, this.form).then(
              (res) => {
                if (res.result && res.code === "200") {
                  this.editVisible = false;
                  this.$message.success("Update successfully");
                } else {
                  this.$message.error(
                    "Update failed, error message: " + res.message
                  );
                }
              }
            );
          } else {
            postData(this.controllerUrl, this.form).then((res) => {
              console.log(res);
              if (res.result && res.code === "200") {
                this.editVisible = false;
                this.$message.success("Create successfully");
                this.form = res.result;
                this.loadData();
              } else {
                this.$message.error(
                  "Create failed, error message: " + res.message
                );
              }
            });
          }
        } else {
          this.$message.error("Validation failed, please correct!");
          return false;
        }
      });
    },
  },
};
</script>
<style>
.ck-editor__editable {
  min-height: 200px;
}
</style>
<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
